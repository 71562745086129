import type { RovoInstrumentedError } from '@atlassian/conversation-assistant-instrumentation';

export class ApiError extends Error implements RovoInstrumentedError {
	constructor(
		public message: string,
		public errorAttributes: {
			/**
			 * Error code / message template from the API - e.g CONVERSATION_CHANNEL_NOT_FOUND
			 */
			errorCode?: string;
			/**
			 * HTTP status code
			 */
			statusCode: number;
			rawErrorMessage?: string;
			traceId?: string;
			requestId?: string;
		},
	) {
		super(message);
	}
}

export const isApiError = (error: unknown): error is ApiError => error instanceof ApiError;

export const mapErrorToApiError = (error: any): ApiError => {
	if (isApiError(error)) {
		return error;
	}

	// Fallback since we expect the error to be an instance of ApiError
	return new ApiError(error.message, {
		statusCode: 0,
	});
};
