// Referenced from jira/src/packages/platform/utils/fetch/src/utils/is-error.tsx

const KNOWN_NETWORK_ERRORS = [
	'... is not valid JSON',
	'server with the specified hostname could not be found',
	'cannot parse response',
	'connection failure',
	'Failed to fetch',
	'in JSON at position 0',
	'JSON.parse: unexpected character at line 1 column 1 of the JSON data',
	'JSON.parse: unexpected end of data at line 1 column 1 of the JSON data',
	'Load failed',
	'Network request failed',
	'NetworkError',
	'Syntax or http error',
	'The request timed out',
	'The network connection was lost',
	'The operation was aborted',
	'Unable to fetch manifest',
	'Unexpected end of input',
	'Unexpected end of JSON input',
	'dynamically imported module',
	'Importing a module script failed',
];

const isClientFetchErrorMessage = (message: string): boolean =>
	Boolean(message) &&
	!!KNOWN_NETWORK_ERRORS.find((networkErrorMessage) =>
		// Using `includes` here because the underlying fetch error messages
		// are sometimes wrapped to provide additional context, e.g.
		// "Something went wrong: ${error.message}"
		message.includes(networkErrorMessage),
	);

const KNOWN_NETWORK_ERRORS_TYPES = ['AbortError', 'AuthError', 'ChunkLoadError', 'NetworkError'];

export const isClientFetchError = (error: any) =>
	Boolean(error) &&
	typeof error === 'object' &&
	(KNOWN_NETWORK_ERRORS_TYPES.includes(error.name) ||
		isClientFetchErrorMessage(error.message) ||
		(typeof error.cause === 'object' &&
			(KNOWN_NETWORK_ERRORS_TYPES.includes(error.cause.name) ||
				isClientFetchErrorMessage(error.cause.message))));
