const pathnamePrefixes = ['/jira/', '/wiki/', '/browse/'];
const ATLAS_HOST = 'home.atlassian.com';
const ATLAS_TEAM_HOST = 'team.atlassian.com';

export const normaliseUrl = (url: string) => {
	try {
		let parsedUrl = new URL(url);

		let normalizedPath = '/any/';
		if (parsedUrl.host === ATLAS_HOST || parsedUrl.host === ATLAS_TEAM_HOST) {
			// Replace org ID with "orgId" in the path
			normalizedPath = parsedUrl.pathname.replace(/\/o\/[^/]+/, '/o/orgId');
		}

		const prefixRegex = new RegExp(`^(${pathnamePrefixes.join('|')})`);
		if (prefixRegex.test(parsedUrl.pathname)) {
			normalizedPath = parsedUrl.pathname.match(prefixRegex)![0];
		}

		const normalizedUrl = `${parsedUrl.protocol}//${parsedUrl.host}${normalizedPath}`;
		return normalizedUrl;
	} catch (error) {
		return 'https://could-not-parse.example.com';
	}
};
