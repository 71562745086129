import type React from 'react';

import {
	createActionsHook,
	createHook,
	createStateHook,
	createStore,
	type StoreActionApi,
} from 'react-sweet-state';

export type ViewConfig = {
	render: () => React.ReactElement;
};
export type AIActionStatus = 'beta' | 'new';

type AISource = {
	sourceId: string;
};

export type BaseAIAction = {
	id: string;
	title: string;
	description?: string;
	icon: React.ReactElement;
	onClick?: () => void;
	viewConfig?: ViewConfig;
	children?: Array<BaseAIAction>;
	closePanelOnSelection?: boolean;
	status?: AIActionStatus;
};

/**
 * Right now we are using the same AIAction type for actions, suggestedActions and nudges
 * but in the future we might want to have different types for each of them.
 */
export type AIAction = BaseAIAction &
	AISource & {
		children?: Array<AIAction>;
	};
/**
 * AI Features will register their features here if they want to render their
 * AI Feature inside the Dynamic Surface Panel
 */
export enum DynamicSurfaceView {
	Default = 'default',
}

export type DynamicSurfaceSelectors = {
	actionsSelector?: (actions: AIAction[]) => AIAction[];
	suggestedActionsSelector?: (suggestedActions: AIAction[]) => AIAction[];
	nudgesSelector?: (nudges: AIAction[]) => AIAction[];
};

export type EditorAISmartButtonReference = {
	reference: string;
	onRemove: () => void;
};

export type BaseAISmartButtonContext = EditorAISmartButtonReference;
export type AISmartButtonContext = BaseAISmartButtonContext & AISource;

export type SurfaceMetadata = {
	siteId: string;
	product: string;
	contentType: string;
	pageMode?: string;
};

export type AISmartButtonState = {
	suggestedActions: AIAction[];
	actions: AIAction[];
	nudges: AIAction[];
	currentView: DynamicSurfaceView | AIAction;
	selectors?: DynamicSurfaceSelectors;
	context?: AISmartButtonContext;
	surfaceMetadata: SurfaceMetadata;
};

export const initialState: AISmartButtonState = {
	suggestedActions: [],
	actions: [],
	nudges: [],
	currentView: DynamicSurfaceView.Default,
	surfaceMetadata: {
		siteId: '',
		product: '',
		contentType: '',
	},
};

/**
 * Adds source ID to each action in the array recursively.
 */
const addSource = (actions: BaseAIAction[], sourceId: string): AIAction[] =>
	actions.map((action) => ({
		...action,
		sourceId,
		children: action.children ? addSource(action.children, sourceId) : undefined,
	}));

/**
 * Updates a collection of items (actions, nudges, or suggestedActions) for a specific source.
 * Only updates if the items have changed to avoid unnecessary re-renders.
 */
const updateItemsForSource = <K extends 'actions' | 'nudges' | 'suggestedActions'>(
	sourceId: string,
	newItems: BaseAIAction[],
	stateKey: K,
	getState: () => AISmartButtonState,
	setState: (state: Partial<AISmartButtonState>) => void,
) => {
	const currentItems = getState()[stateKey];
	const currentSourceItems = currentItems.filter((item) => item.sourceId === sourceId);

	// Check if items are the same by comparing IDs in order.
	const itemIdsMatch =
		currentSourceItems.length === newItems.length &&
		currentSourceItems.every((item, index) => item.id === newItems[index].id);

	if (itemIdsMatch) {
		return;
	}

	// Filter out items from this source and add new ones.
	const otherItems = currentItems.filter((item) => item.sourceId !== sourceId);
	const updatedItems = [...otherItems, ...addSource(newItems, sourceId)];

	setState({ [stateKey]: updatedItems });
};

export const actions = {
	registerActions:
		(newActions: AIAction[]) =>
		({ setState, getState }: StoreActionApi<AISmartButtonState>) => {
			const { actions: existingActions } = getState();
			setState({ actions: [...existingActions, ...newActions] });
		},
	registerNudges:
		(newNudges: AIAction[]) =>
		({ setState, getState }: StoreActionApi<AISmartButtonState>) => {
			const { nudges: existingNudges } = getState();
			setState({ nudges: [...existingNudges, ...newNudges] });
		},
	registerSuggestedActions:
		(newSuggestedActions: AIAction[]) =>
		({ setState, getState }: StoreActionApi<AISmartButtonState>) => {
			const { suggestedActions: existingSuggestedActions } = getState();
			setState({ suggestedActions: [...existingSuggestedActions, ...newSuggestedActions] });
		},
	removeActions:
		(ids: string[]) =>
		({ getState, setState }: StoreActionApi<AISmartButtonState>) => {
			const { actions } = getState();
			setState({
				actions: actions.filter((action) => !ids.includes(action.id)),
			});
		},
	removeNudges:
		(ids: string[]) =>
		({ getState, setState }: StoreActionApi<AISmartButtonState>) => {
			const { nudges } = getState();
			setState({
				nudges: nudges.filter((action) => !ids.includes(action.id)),
			});
		},
	removeSuggestedActions:
		(ids: string[]) =>
		({ getState, setState }: StoreActionApi<AISmartButtonState>) => {
			const { suggestedActions } = getState();
			setState({
				suggestedActions: suggestedActions.filter((action) => !ids.includes(action.id)),
			});
		},
	setActions:
		(sourceId: string, actions: BaseAIAction[]) =>
		({ setState, getState }: StoreActionApi<AISmartButtonState>) => {
			updateItemsForSource(sourceId, actions, 'actions', getState, setState);
		},
	setNudges:
		(sourceId: string, nudges: BaseAIAction[]) =>
		({ setState, getState }: StoreActionApi<AISmartButtonState>) => {
			updateItemsForSource(sourceId, nudges, 'nudges', getState, setState);
		},
	setSuggestedActions:
		(sourceId: string, suggestedActions: BaseAIAction[]) =>
		({ setState, getState }: StoreActionApi<AISmartButtonState>) => {
			updateItemsForSource(sourceId, suggestedActions, 'suggestedActions', getState, setState);
		},
	removeAll:
		(sourceId?: string) =>
		({ getState, setState }: StoreActionApi<AISmartButtonState>) => {
			const { actions, nudges, suggestedActions } = getState();

			if (sourceId) {
				setState({
					suggestedActions: suggestedActions.filter((action) => action.sourceId !== sourceId),
					actions: actions.filter((action) => action.sourceId !== sourceId),
					nudges: nudges.filter((nudge) => nudge.sourceId !== sourceId),
				});
			} else {
				setState({
					suggestedActions: [],
					actions: [],
					nudges: [],
				});
			}
		},
	changeView:
		(view: DynamicSurfaceView | AIAction) =>
		({ setState }: StoreActionApi<AISmartButtonState>) => {
			setState({ currentView: view });
		},
	setSelectors:
		({ actionsSelector, suggestedActionsSelector, nudgesSelector }: DynamicSurfaceSelectors) =>
		({ setState, getState }: StoreActionApi<AISmartButtonState>) => {
			const existingSelectors = getState().selectors;
			setState({
				selectors: {
					actionsSelector: actionsSelector ?? existingSelectors?.actionsSelector,
					suggestedActionsSelector:
						suggestedActionsSelector ?? existingSelectors?.suggestedActionsSelector,
					nudgesSelector: nudgesSelector ?? existingSelectors?.nudgesSelector,
				},
			});
		},
	setContext:
		(context: AISmartButtonContext) =>
		({ setState }: StoreActionApi<AISmartButtonState>) => {
			setState({
				context,
			});
		},
	removeContext:
		(sourceId: AISmartButtonContext['sourceId']) =>
		({ setState, getState }: StoreActionApi<AISmartButtonState>) => {
			const { context } = getState();
			if (context?.sourceId === sourceId) {
				setState({ context: undefined });
			}
		},
	setSurfaceMetadata:
		(surfaceMetadata: SurfaceMetadata) =>
		({ setState }: StoreActionApi<AISmartButtonState>) => {
			setState({ surfaceMetadata });
		},
};

const AISmartButtonStore = createStore({
	initialState,
	actions,
	name: 'AISmartButtonStore',
});

export const useAISmartButton = createHook(AISmartButtonStore);
export const useAISmartButtonActions = createActionsHook(AISmartButtonStore);
export const useAISmartButtonState = createStateHook(AISmartButtonStore);

export type AISmartButtonActions = ReturnType<typeof useAISmartButtonActions>;
