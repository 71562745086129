//Shared code with conversation-assistant-service/src/common/utils/fetch.ts

import { FetchError } from './fetch-error';

type FetchOptions = {
	path: string;
	options: RequestInit;
};

//TODO have this extend RovoInstrumentedError once that PR is merged
class HttpApiError extends Error {
	constructor(
		public message: string,
		public errorAttributes: {
			statusCode: number;
		},
	) {
		super(message);
	}
}

export const performHttpFetch = async ({ path, options }: FetchOptions) => {
	try {
		const response = await fetch(path, options);
		if (!response.ok || response.status >= 400) {
			await processHttpErrorResponse({ response });
		}
		return await response.json();
	} catch (err) {
		throw new FetchError(`performHttpFetch error whle fetching ${path}`, { cause: err });
	}
};

const processHttpErrorResponse = async ({
	response,
}: {
	response: Response;
}): Promise<Response> => {
	throw new HttpApiError(`[Code ${response.status}] ${await response.text()}`, {
		statusCode: response.status,
	});
};
