export class FetchError extends Error {
	constructor(
		message: string,
		public cause?: unknown,
	) {
		super(message);
		this.name = 'FetchError';
		if (cause) {
			this.cause = cause;
		}
	}
}
